var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
    ? _c(
        "div",
        {
          staticClass: "ecommerce-application",
          staticStyle: { height: "inherit" }
        },
        [
          _c("cameraconsole-list-filters", {
            attrs: {
              "user-data": _vm.userData,
              "client-filter": _vm.clientFilter,
              "clients-list": _vm.clientsList,
              "view-type-filter": _vm.viewTypeFilter,
              "view-type-options": _vm.viewTypeOptions,
              "site-filter": _vm.siteFilter,
              "site-options": _vm.siteOptions,
              "status-filter": _vm.statusFilter,
              "status-options": _vm.statusOptions
            },
            on: {
              "update:userData": function($event) {
                _vm.userData = $event
              },
              "update:user-data": function($event) {
                _vm.userData = $event
              },
              "update:clientFilter": function($event) {
                _vm.clientFilter = $event
              },
              "update:client-filter": function($event) {
                _vm.clientFilter = $event
              },
              "update:viewTypeFilter": function($event) {
                _vm.viewTypeFilter = $event
              },
              "update:view-type-filter": function($event) {
                _vm.viewTypeFilter = $event
              },
              "update:siteFilter": function($event) {
                _vm.siteFilter = $event
              },
              "update:site-filter": function($event) {
                _vm.siteFilter = $event
              },
              "update:statusFilter": function($event) {
                _vm.statusFilter = $event
              },
              "update:status-filter": function($event) {
                _vm.statusFilter = $event
              }
            }
          }),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                  attrs: { cols: "12", md: "6" }
                },
                [
                  _c("label", [_vm._v("Show")]),
                  _c("v-select", {
                    staticClass: "per-page-selector d-inline-block mx-50",
                    attrs: {
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      options: _vm.perPageOptions,
                      clearable: false
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage"
                    }
                  }),
                  _c("label", [_vm._v("entries")]),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        variant: "primary",
                        disabled: _vm.disableRefresh
                      },
                      on: { click: _vm.refreshTheList }
                    },
                    [
                      _c("feather-icon", { attrs: { icon: "RefreshCcwIcon" } }),
                      _c("span", { staticClass: "text-nowrap ml-1" }, [
                        _vm._v("Refresh")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-items-center justify-content-end"
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "d-inline-block mr-1",
                      attrs: { placeholder: "Search..." },
                      model: {
                        value: _vm.searchQuery,
                        callback: function($$v) {
                          _vm.searchQuery = $$v
                        },
                        expression: "searchQuery"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm.viewTypeFilter === "cameraBySites"
            ? _vm._l(_vm.cameras, function(siteItem) {
                return _c(
                  "div",
                  {
                    key: "theSite-" + siteItem.sitename,
                    staticClass: "ml-1 mr-1"
                  },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mt-2 mb-1 border-bottom-primary" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-start mb-1 mb-md-0"
                          },
                          [
                            _c("h5", { staticClass: "mb-0" }, [
                              _c("h4", [_vm._v(_vm._s(siteItem.sitename))])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(siteItem.cameras, function(camera) {
                        return _c(
                          "b-card",
                          {
                            key: camera.camera_id,
                            class:
                              camera.latest_alert !== undefined &&
                              camera.latest_alert_show &&
                              "border-danger",
                            attrs: { "no-body": "" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "item-img text-center" },
                              [
                                _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "apps-cameraconsole-edit",
                                        params: { cameraid: camera.camera_id }
                                      }
                                    }
                                  },
                                  [
                                    camera.latest_image !== undefined &&
                                    camera.latest_image.access_url !== ""
                                      ? _c("thumbs-lazy-image-component", {
                                          staticClass: "card-img-top",
                                          attrs: {
                                            src:
                                              camera.latest_alert !==
                                                undefined &&
                                              camera.latest_alert_show
                                                ? camera.latest_alert.access_url
                                                : camera.latest_image.access_url
                                          }
                                        })
                                      : _c("b-img", {
                                          staticClass: "card-img-top",
                                          attrs: {
                                            alt:
                                              camera.unit_number +
                                              "-" +
                                              camera.camera_id,
                                            fluid: "",
                                            src: require("@/assets/images/noImage.png")
                                          }
                                        })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-card-body",
                              { staticClass: "pb-1" },
                              [
                                _c(
                                  "b-card-title",
                                  { staticClass: "mb-0" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "col-3 text-right pr-0 text-body"
                                          },
                                          [_c("small", [_vm._v("Site:")])]
                                        ),
                                        _c(
                                          "b-col",
                                          { staticClass: "col-9 text-body" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(camera.sitename) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "col-3 text-right pr-0 text-body"
                                          },
                                          [_c("small", [_vm._v("Location:")])]
                                        ),
                                        _c(
                                          "b-col",
                                          { staticClass: "col-9 text-body" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(camera.location_name) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-card-text",
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "col-3 text-right pr-0 text-body"
                                          },
                                          [_c("small", [_vm._v("Camera:")])]
                                        ),
                                        _c(
                                          "b-col",
                                          { staticClass: "col-9 text-body" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(camera.unit_number) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-card-footer",
                              { staticClass: "pt-1 pb-1" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "col-3 text-right pr-0 text-body"
                                      },
                                      [_c("small", [_vm._v("Status:")])]
                                    ),
                                    _c(
                                      "b-col",
                                      { staticClass: "col-9 text-body" },
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            staticClass: "text-capitalize",
                                            attrs: {
                                              pill: "",
                                              variant:
                                                "light-" +
                                                _vm.resolveStatusVariant(
                                                  camera.is_active,
                                                  camera.is_online
                                                )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.resolveStatusVariantText(
                                                    camera.is_active,
                                                    camera.is_online
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                camera.latest_alert !== undefined &&
                                camera.latest_alert_show
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "col-3 text-right pr-0 text-body"
                                              },
                                              [_c("small", [_vm._v("Alert:")])]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "col-9 text-body"
                                              },
                                              [
                                                _c(
                                                  "b-badge",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "light-danger"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          camera.latest_alert
                                                            .message
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "col-3 text-right pr-0 text-body"
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v("Alert Date:")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "col-9 text-body"
                                              },
                                              [
                                                camera.latest_alert !==
                                                undefined
                                                  ? _c("small", [
                                                      _vm._v(
                                                        _vm._s(
                                                          camera.latest_alert.time_created.replace(
                                                            "T",
                                                            " "
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "col-3 text-right pr-0 text-body"
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v("Image Date:")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "col-9 text-body"
                                              },
                                              [
                                                camera.latest_image !==
                                                undefined
                                                  ? _c("small", [
                                                      _vm._v(
                                                        _vm._s(
                                                          camera.latest_image.time_created.replace(
                                                            "T",
                                                            " "
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "col-3 text-right pr-0 text-body"
                                              },
                                              [_c("small", [_vm._v("Alert:")])]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "col-9 text-body"
                                              },
                                              [
                                                _c(
                                                  "b-badge",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "light-success"
                                                    }
                                                  },
                                                  [_vm._v(" OK ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              })
            : _c(
                "section",
                { staticClass: "grid-view" },
                _vm._l(_vm.cameras, function(camera) {
                  return _c(
                    "b-card",
                    {
                      key: camera.camera_id,
                      class:
                        camera.latest_alert !== undefined &&
                        camera.latest_alert_show &&
                        "border-danger",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "item-img text-center" },
                        [
                          _c(
                            "b-link",
                            {
                              attrs: {
                                to: {
                                  name: "apps-cameraconsole-edit",
                                  params: { cameraid: camera.camera_id }
                                }
                              }
                            },
                            [
                              camera.latest_image !== undefined &&
                              camera.latest_image.access_url !== undefined &&
                              camera.latest_image.access_url !== ""
                                ? _c("thumbs-lazy-image-component", {
                                    staticClass: "card-img-top",
                                    attrs: {
                                      src:
                                        camera.latest_alert !== undefined &&
                                        camera.latest_alert_show
                                          ? camera.latest_alert.access_url
                                          : camera.latest_image.access_url
                                    }
                                  })
                                : _c("b-img", {
                                    staticClass: "card-img-top",
                                    attrs: {
                                      alt:
                                        camera.unit_number +
                                        "-" +
                                        camera.camera_id,
                                      fluid: "",
                                      src: require("@/assets/images/noImage.png")
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-link",
                        {
                          attrs: {
                            to: {
                              name: "apps-cameraconsole-edit",
                              params: { cameraid: camera.camera_id }
                            }
                          }
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "pb-1" },
                            [
                              _c(
                                "b-card-title",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "col-3 text-right pr-0"
                                        },
                                        [_c("small", [_vm._v("Site:")])]
                                      ),
                                      _c("b-col", { staticClass: "col-9" }, [
                                        _vm._v(
                                          " " + _vm._s(camera.sitename) + " "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "col-3 text-right pr-0"
                                        },
                                        [_c("small", [_vm._v("Location:")])]
                                      ),
                                      _c("b-col", { staticClass: "col-9" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(camera.location_name) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-card-text",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "col-3 text-right pr-0 text-body"
                                        },
                                        [_c("small", [_vm._v("Camera:")])]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "col-9 text-body" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(camera.unit_number) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-card-footer",
                            { staticClass: "pt-1 pb-1" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "col-3 text-right pr-0 text-body"
                                    },
                                    [_c("small", [_vm._v("Status:")])]
                                  ),
                                  _c(
                                    "b-col",
                                    { staticClass: "col-9" },
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            pill: "",
                                            variant:
                                              "light-" +
                                              _vm.resolveStatusVariant(
                                                camera.is_active,
                                                camera.is_online
                                              )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.resolveStatusVariantText(
                                                  camera.is_active,
                                                  camera.is_online
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              camera.latest_alert !== undefined &&
                              camera.latest_alert_show
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "col-3 text-right pr-0 text-body"
                                            },
                                            [_c("small", [_vm._v("Alert:")])]
                                          ),
                                          _c(
                                            "b-col",
                                            { staticClass: "col-9" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass:
                                                    "text-capitalize",
                                                  attrs: {
                                                    pill: "",
                                                    variant: "light-danger"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        camera.latest_alert
                                                          .message
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "col-3 text-right pr-0 text-body"
                                            },
                                            [_c("small", [_vm._v("Date:")])]
                                          ),
                                          _c(
                                            "b-col",
                                            { staticClass: "col-9 text-body" },
                                            [
                                              camera.latest_alert !== undefined
                                                ? _c("small", [
                                                    _vm._v(
                                                      _vm._s(
                                                        camera.latest_alert.time_created.replace(
                                                          "T",
                                                          " "
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "col-3 text-right pr-0 text-body"
                                            },
                                            [_c("small", [_vm._v("Date:")])]
                                          ),
                                          _c(
                                            "b-col",
                                            { staticClass: "col-9 text-body" },
                                            [
                                              camera.latest_image !== undefined
                                                ? _c("small", [
                                                    _vm._v(
                                                      _vm._s(
                                                        camera.latest_image.time_created.replace(
                                                          "T",
                                                          " "
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "col-3 text-right pr-0 text-body"
                                            },
                                            [_c("small", [_vm._v("Alert:")])]
                                          ),
                                          _c(
                                            "b-col",
                                            { staticClass: "col-9 text-body" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass:
                                                    "text-capitalize",
                                                  attrs: {
                                                    pill: "",
                                                    variant: "light-success"
                                                  }
                                                },
                                                [_vm._v(" OK ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
          _c(
            "section",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalItems,
                          "per-page": _vm.perPage,
                          "first-number": "",
                          align: "right",
                          "last-number": "",
                          "prev-class": "prev-item",
                          "next-class": "next-item"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prev-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronLeftIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "next-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronRightIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1308952388
                        ),
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }